import { useState } from "react";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@reach/tabs";
import styled from "styled-components";
import { Back, Breadcrumb, List, Spacer, Text } from "../../components/shared";
import COLORS from "../../template/colors";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";

export const Notifications = () => {
  const [isModalVisible, setModalVisible] = useState(false);

  return (
    <>
      <div className="row">
        <Spacer size={47} />
        <Flex>
          <Back />
          <div>
            <Text type="h2" color={COLORS.white}>
              Notificações
            </Text>
          </div>
        </Flex>
        <Spacer size={4} />
        <Breadcrumb
          margin="0 0 0 60px"
          items={[{ name: "Notificações", url: "/notificacoes" }]}
        />
      </div>
      <Spacer size={108} />

      <Tabs>
        <TabList>
          <Tab>Todos</Tab>
          <Tab>Comunicados</Tab>
          <Tab>Interações</Tab>
        </TabList>
        <Spacer size={40} />
        <TabPanels>
          <TabPanel>
            <List
              info={{
                person: {
                  image: "https://randomuser.me/api/portraits/thumb/men/55.jpg",
                  name: "Carlos Santana",
                  status: "Desenvolvedor Full Stack",
                },
                text: {
                  icon: "glass",
                  title: "Está celebrando 3 anos trabalhando na rethink!",
                  status: "Há um minuto",
                },
              }}
            />
          </TabPanel>
          <TabPanel>
            <List
              info={{
                person: {
                  image: "https://randomuser.me/api/portraits/thumb/men/56.jpg",
                  name: "Carlos Santanaaaaaaaa",
                  status: "Desenvolvedor Full Stack",
                },
                text: {
                  icon: "notice",
                  iconBgColor: COLORS.black,
                  title: "Lorem ipsum dolor sit amet consectetur vitae orcia",
                  status: (
                    <button onClick={() => setModalVisible(true)}>
                      <Text type="button" color={COLORS.primary}>
                        Ver comunicado
                      </Text>
                    </button>
                  ),
                },
              }}
            />
          </TabPanel>
          <TabPanel>
            <p>three!</p>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Dialog isOpen={isModalVisible} onDismiss={() => setModalVisible(false)}>
        <button className="close-button" onClick={() => setModalVisible(false)}>
          <span aria-hidden>×</span>
        </button>
        <Text type="h4" color={COLORS.navyBlue}>
          Lorem ipsum dolor sit amet consectetur: Quis dignissim bibendum
          euismod amet faucibus.
        </Text>
        <Spacer size={8} />
        <Text type="body2" color={COLORS.gray}>
          9 de janeiro de 2023
        </Text>
        <Spacer size={16} />
        <Text type="body1" color={COLORS.gray}>
          Lorem ipsum dolor sit amet consectetur. Nec diam habitant sed vitae
          nec in ac vel feugiat. A velit amet blandit quis et. Tristique mauris
          lorem sit faucibus id lorem posuere a. Penatibus vitae porttitor eget
          pulvinar convallis nunc eget. Placerat proin justo consectetur rhoncus
          consequat montes amet ultrices. Amet sagittis turpis imperdiet aliquam
          bibendum quis consectetur ultrices blandit. Dictumst nibh morbi
          pulvinar ultrices neque. Pulvinar sodales phasellus justo sit justo.
          Auctor turpis dolor dui mi quis morbi. Proin cursus lectus id sit
          hendrerit scelerisque volutpat bibendum. Sociis lectus enim mi nulla
          velit ut urna platea massa. Tellus metus vivamus varius tristique.
          Vitae ornare amet lorem feugiat. Ullamcorper blandit aliquam laoreet
          id eget mollis et arcu nisl. Enim eleifend quis lorem ornare.
          Tincidunt massa potenti sagittis arcu in tortor. Purus adipiscing quam
          dictumst adipiscing ut. Volutpat et pharetra id pulvinar nibh vel
          amet. A lacus at enim tristique ut viverra nibh et diam. Pellentesque
          dictum pellentesque lorem neque faucibus mauris a. Sit magna maecenas
          aliquam leo nec massa risus phasellus. Ornare a porta feugiat volutpat
          eget. Diam auctor faucibus nec sodales. Nulla luctus venenatis sed in
          gravida. Pharetra lobortis dignissim eget dictum cursus massa in. Nisl
          pretium turpis blandit lectus ac faucibus vel venenatis. Sed tortor
          diam phasellus venenatis. Viverra posuere diam quam pharetra neque
          dictum risus.
        </Text>
      </Dialog>
    </>
  );
};

const Flex = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;
`;

import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { Sidebar } from "./Sidebar";
import COLORS from "./colors";

export const Template = () => (
  <div className="container-fluid p-0">
    <div className="row">
      <div className="col-auto p-0">
        <Sidebar />
      </div>
      <div className="col p-0">
        <Container>
          <Outlet />
        </Container>
      </div>
    </div>
  </div>
);

const Container = styled.div`
  background: center top no-repeat;
  background-image: url("/assets/images/header.png"),
    linear-gradient(175deg, ${COLORS.primary}, ${COLORS.black} 200px);
  background-size: auto 210px;
  padding: 0 44px;
  min-height: 100%;
`;

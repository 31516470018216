import styled from "styled-components";
import COLORS from "../../../template/colors";
import { Icon } from "..";
import { useNavigate } from "react-router-dom";

export const Back = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Button onClick={() => navigate(-1)}>
      <Icon icon="back" color={COLORS.white} width={13} />
    </Button>
  );
};

const Button = styled.button`
  background: none;
  border: 1px solid ${COLORS.white};
  width: 45px;
  height: 45px;
  border-radius: 50%;
  cursor: pointer;
`;

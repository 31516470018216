const COLORS = {
  primary: "#17bfa0",
  navyBlue: "#191a22",
  black: "#202020",
  gray: "#5e5f63",
  grayBg: "#f5f5f5",
  white: "#fff",
};

export default COLORS;

import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from "react-router-dom";
import Template from "./template";
import {
  Home,
  Notifications,
  Feedback,
  Celebrations,
  Users,
  Profile,
} from "./pages";

export const routes = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Template />}>
      <Route path="/" element={<Navigate to="/inicio" replace={true} />} />
      <Route path="/inicio" element={<Home />} />
      <Route path="/notificacoes" element={<Notifications />} />
      <Route path="/feedback" element={<Feedback />} />
      <Route path="/celebracoes" element={<Celebrations />} />
      <Route path="/usuarios" element={<Users />} />
      <Route path="/perfil/:id" element={<Profile />} />
    </Route>
  )
);

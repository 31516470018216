import { useState } from "react";
import styled from "styled-components";
import { Icon, Spacer, Text } from "../components/shared";
import { getEmployees } from "../api/user";
import COLORS from "./colors";
import { Input } from "../components/shared/form";
import { Link, useLocation } from "react-router-dom";
import { IconTypes } from "../components/shared/icon/Icon";

interface EmployeeType {
  location: {
    state: string;
  };
  name: {
    first: string;
    last: string;
  };
  picture: {
    thumbnail: string;
  };
}

export const Sidebar = () => {
  const [isSubMenuOpened, setSubMenuOpened] = useState(false);
  const [employees, setEmployees] = useState<EmployeeType[]>([]);
  const [filteredEmployees, setFilteredEmployees] = useState<EmployeeType[]>(
    []
  );
  const [isSearchingEmployee, setSearchingEmployee] = useState(false);
  const location = useLocation();

  const onSearch = async (text: string) => {
    if (employees.length === 0) {
      setEmployees(await getEmployees());
    } else {
      const filtered = employees.filter((employee) =>
        employee.name.first.toLowerCase().includes(text.toLowerCase())
      );

      setFilteredEmployees(filtered);
      setSearchingEmployee(!!text);
    }
  };

  const isActive = (url: string) => location.pathname.includes(url);

  const MENU_ITEMS = [
    {
      id: "inicio",
      label: "Início",
      icon: "home",
    },
    {
      id: "notificacoes",
      label: "Notificações",
      icon: "bell",
    },
    {
      id: "feedback",
      label: "Feedback",
      icon: "chat",
    },
    {
      id: "celebracoes",
      label: "Celebrações",
      icon: "glass",
    },
    {
      id: "usuarios",
      label: "Usuários",
      icon: "users",
    },
    {
      id: "dashboards",
      label: "Dashboards",
      icon: "dashboard",
    },
    {
      id: "configuracoes",
      label: "Configurações",
      icon: "gear",
    },
  ];

  return (
    <Wrapper>
      <Spacer size={50} />
      <img src="/assets/images/logo.png" alt="Rethink" />
      <Spacer size={36} />
      <User>
        <svg
          width="55"
          height="68"
          viewBox="0 0 55 68"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask id="path-1-inside-1_156_549" fill="white">
            <path d="M0 6C0 2.68629 2.69068 -0.0252794 5.99351 0.243107C14.86 0.963591 23.5409 3.27842 31.6136 7.09273C39.6862 10.907 46.9867 16.1434 53.1734 22.5355C55.4779 24.9166 55.0917 28.717 52.5318 30.8212L9.8099 65.9367C5.89467 69.1548 0 66.3696 0 61.3015V6Z" />
          </mask>
          <path
            d="M0 6C0 2.68629 2.69068 -0.0252794 5.99351 0.243107C14.86 0.963591 23.5409 3.27842 31.6136 7.09273C39.6862 10.907 46.9867 16.1434 53.1734 22.5355C55.4779 24.9166 55.0917 28.717 52.5318 30.8212L9.8099 65.9367C5.89467 69.1548 0 66.3696 0 61.3015V6Z"
            fill={COLORS.primary}
            stroke={COLORS.primary}
            strokeWidth="2"
            mask="url(#path-1-inside-1_156_549)"
          />
        </svg>
        <UserPicture src="/assets/images/user.jpg" alt="User" />
      </User>
      <Spacer size={8} />
      <button onClick={() => setSubMenuOpened(!isSubMenuOpened)}>
        <Text type="h5" color={COLORS.black} align="center">
          Marina Lima{" "}
          <Icon
            icon={isSubMenuOpened ? "arrow-down" : "arrow-up"}
            color={COLORS.black}
            width={12}
          />
        </Text>
      </button>
      {isSubMenuOpened && (
        <SubMenu>
          <ul>
            <li>
              <MenuItem to="/perfil/5" isActive={false}>
                <Icon icon="user" color={COLORS.primary} />
                <Text type="sub2" color={COLORS.navyBlue}>
                  Ver Perfil
                </Text>
              </MenuItem>
            </li>
            <li>
              <MenuItem to="/logout" isActive={false}>
                <Icon icon="exit" color={COLORS.primary} />
                <Text type="sub2" color={COLORS.navyBlue}>
                  Sair
                </Text>
              </MenuItem>
            </li>
          </ul>
        </SubMenu>
      )}

      <Text type="body1" color={COLORS.gray} align="center">
        Auxiliar Administrativo
      </Text>
      <Spacer size={40} />
      <Input
        type="text"
        placeholder="Buscar colaborador"
        icon="search"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onSearch(e.target.value)
        }
      />
      <Spacer size={40} />
      {isSearchingEmployee ? (
        <EmployeeList>
          <ul>
            {filteredEmployees.length > 0 ? (
              filteredEmployees.map((employee) => (
                <>
                  <EmployeeItem>
                    <a href="#">
                      <img
                        src={employee.picture.thumbnail}
                        alt={employee.name.first}
                      />
                      <div>
                        <Text type="sub1" color={COLORS.navyBlue}>
                          {employee.name.first} {employee.name.last}
                        </Text>
                        <Text type="body2" color={COLORS.gray}>
                          {employee.location.state}
                        </Text>
                      </div>
                    </a>
                  </EmployeeItem>
                  <Spacer size={22} />
                </>
              ))
            ) : (
              <Text type="body2" color={COLORS.gray} align="center">
                Nenhum colaborador encontrado
              </Text>
            )}
          </ul>
        </EmployeeList>
      ) : (
        <Menu>
          <ul>
            {MENU_ITEMS.map((item) => (
              <li>
                <MenuItem to={`/${item.id}`} isActive={isActive(item.id)}>
                  <Icon
                    icon={item.icon as IconTypes}
                    color={isActive(item.id) ? COLORS.white : COLORS.primary}
                  />
                  <Text
                    type={isActive(item.id) ? "sub1" : "sub2"}
                    color={isActive(item.id) ? COLORS.white : COLORS.navyBlue}
                  >
                    {item.label}
                  </Text>
                  {item.id === "notificacoes" && (
                    <Circle
                      color={isActive(item.id) ? COLORS.white : COLORS.primary}
                    />
                  )}
                </MenuItem>
              </li>
            ))}
          </ul>
        </Menu>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: ${COLORS.grayBg};
  width: 310px;
  min-height: 100vh;
  height: 100%;
  text-align: center;
  padding: 0 24px;
  box-sizing: border-box;
`;

const User = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: -5px;
  }
`;

const UserPicture = styled.img`
  border-radius: 50%;
  display: block;
  margin: auto;
  outline: 1px solid #e0e0e0;
  padding: 8px;
  position: relative;
`;

const EmployeeList = styled.nav`
  text-align: left;
  height: 345px;
  overflow-y: scroll;
`;

const EmployeeItem = styled.li`
  img {
    border-radius: 50%;
  }

  a {
    display: flex;
    column-gap: 16px;
  }
`;

const Menu = styled.nav`
  text-align: left;
  height: 345px;
`;

const MenuItem = styled(Link)<{ isActive: boolean }>`
  display: flex;
  background-color: ${({ isActive }: any) =>
    isActive ? COLORS.primary : "transparent"};
  border-radius: 8px;
  padding: 13px 24px;
  align-items: center;
  column-gap: 16px;
`;

const Circle = styled.span`
  width: 8px;
  height: 8px;
  display: inline-block;
  background-color: ${({ color }: any) => color};
  border-radius: 50%;
`;

const SubMenu = styled.nav`
  position: relative;

  ul {
    position: absolute;
    text-align: left;
    background-color: ${COLORS.white};
    border-radius: 8px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
    width: 100%;
    top: 3px;
    padding: 4px 0;
  }
`;

import styled from "styled-components";

interface Props {
  size: number;
  direction?: "horizontal" | "vertical";
}

export const Spacer = ({
  direction = "horizontal",
  size,
}: Props): JSX.Element => {
  const SPACER_CONFIG = {
    horizontal: {
      display: "block",
      width: "100%",
      height: `${size}px`,
    },
    vertical: {
      display: "inline-block",
      width: `${size}px`,
      height: "100%",
    },
  };
  return <Element config={SPACER_CONFIG[direction]} />;
};

const Element = styled.span<{ config: any }>`
  display: ${({ config }: any) => config.display};
  width: ${({ config }: any) => config.width};
  height: ${({ config }: any) => config.height};
`;

import styled from "styled-components";

interface Props {
  type:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "sub1"
    | "sub2"
    | "body1"
    | "body2"
    | "button"
    | "caption"
    | "overline";
  color: string;
  children: React.ReactNode;
  align?: "left" | "center" | "right";
}

export const Text = ({ type, color, children, align = "left" }: Props) => {
  const style = {
    h1: {
      fontWeight: "600",
      fontSize: "48px",
      lineHeight: "68px",
    },
    h2: {
      fontWeight: "600",
      fontSize: "40px",
      lineHeight: "57px",
    },
    h3: {
      fontWeight: "600",
      fontSize: "32px",
      lineHeight: "46px",
    },
    h4: {
      fontWeight: "600",
      fontSize: "24px",
      lineHeight: "34px",
    },
    h5: {
      fontWeight: "600",
      fontSize: "20px",
      lineHeight: "28px",
    },
    sub1: {
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "23px",
    },
    sub2: {
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "23px",
    },
    body1: {
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "23px",
    },
    body2: {
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "20px",
    },
    button: {
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "120%",
    },
    caption: {
      fontWeight: "300",
      fontSize: "12px",
      lineHeight: "17px",
    },
    overline: {
      fontWeight: "400",
      fontSize: "10px",
      lineHeight: "14px",
    },
  } as any;

  return (
    <Base color={color} style={style[type]} align={align}>
      {children}
    </Base>
  );
};

const Base = styled.p<any>`
  font-family: "Source Sans 3";
  font-style: normal;
  color: ${({ color }: any) => color};
  text-align: ${({ align }: any) => align};
  font-weight: ${({ style }: any) => style.fontWeight};
  font-size: ${({ style }: any) => style.fontSize};
  line-height: ${({ style }: any) => style.lineHeight};
`;

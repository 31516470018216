import { Link } from "react-router-dom";
import styled from "styled-components";
import { Card, Icon, List, Spacer, Text } from "../../components/shared";
import { capitalizeFirstLetter } from "../../helpers";
import COLORS from "../../template/colors";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import { useState } from "react";

export const Home = () => {
  const [isModalVisible, setModalVisible] = useState(false);

  const getMessage = () => {
    const hour = new Date().getHours();
    return hour >= 6 && hour <= 11
      ? "bom dia"
      : hour >= 12 && hour <= 18
      ? "boa tarde"
      : "boa noite";
  };

  const getTodayDate = () => {
    const today = new Date();
    return today.toLocaleString("default", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };
  return (
    <>
      <div className="row">
        <Spacer size={47} />
        <Text type="h2" color={COLORS.white}>
          Olá, {getMessage()}!
        </Text>
        <Spacer size={5} />
        <Flex>
          <Flex>
            <Icon icon="weather" color={COLORS.white} width={20} />
            <Text type="sub2" color={COLORS.white}>
              {capitalizeFirstLetter(getTodayDate())}
            </Text>
          </Flex>
          <Text type="sub1" color={COLORS.white}>
            |
          </Text>
          <Flex>
            <Icon icon="cake" color={COLORS.white} width={14} />
            <FlexBirth>
              <Text type="sub1" color={COLORS.white}>
                Aniversariantes do dia:
              </Text>
              <Text type="sub2" color={COLORS.white}>
                Márcia Xavier, Charles Vinícius Santos, Manoel Rodrigues...
              </Text>
              <button onClick={() => setModalVisible(true)}>
                <Icon icon="plus" color={COLORS.white} />
              </button>
            </FlexBirth>
          </Flex>
        </Flex>
      </div>
      <Spacer size={35} />
      <div className="row">
        <div className="col">
          <Card icon="chat" title="Feedback">
            <Text type="sub2" color={COLORS.navyBlue}>
              Você não recebe feedback há
            </Text>
            <Spacer size={4} />
            <Text type="h4" color={COLORS.navyBlue}>
              15 dias
            </Text>
            <Spacer size={23} />
            <Link to="#">
              <Text type="button" color={COLORS.primary}>
                Solicitar feedback
              </Text>
            </Link>
          </Card>
        </div>
        <div className="col">
          <Card icon="send" title="Feedback">
            <Text type="sub2" color={COLORS.navyBlue}>
              Você não envia feedback há
            </Text>
            <Spacer size={4} />
            <Text type="h4" color={COLORS.navyBlue}>
              3 dias
            </Text>
            <Spacer size={23} />
            <Link to="#">
              <Text type="button" color={COLORS.primary}>
                Enviar feedback
              </Text>
            </Link>
          </Card>
        </div>
        <div className="col">
          <Card icon="glass" title="Celebrações">
            <Text type="sub2" color={COLORS.navyBlue}>
              Você não celebra algo há
            </Text>
            <Spacer size={4} />
            <Text type="h4" color={COLORS.navyBlue}>
              10 dias
            </Text>
            <Spacer size={23} />
            <Link to="#">
              <Text type="button" color={COLORS.primary}>
                Celebrar
              </Text>
            </Link>
          </Card>
        </div>
      </div>
      <Spacer size={40} />
      <Text type="h4" color={COLORS.navyBlue}>
        Atividades Recentes
      </Text>
      <Spacer size={20} />
      <List
        info={{
          person: {
            image: "https://randomuser.me/api/portraits/thumb/men/55.jpg",
            name: "Carlos Santana",
            status: "Desenvolvedor Full Stack",
          },
          text: {
            icon: "glass",
            title: "Está celebrando 3 anos trabalhando na rethink!",
            status: "Há um minuto",
          },
        }}
      />
      <List
        info={{
          person: {
            image: "https://randomuser.me/api/portraits/thumb/men/56.jpg",
            name: "Carlos Santanaaaaaaaa",
            status: "Desenvolvedor Full Stack",
          },
          text: {
            icon: "glass",
            title: "Está celebrando 3 anos trabalhando na rethink!",
            status: "Há um minuto",
          },
        }}
      />

      <Dialog isOpen={isModalVisible} onDismiss={() => setModalVisible(false)}>
        <button className="close-button" onClick={() => setModalVisible(false)}>
          <span aria-hidden>×</span>
        </button>
        <Cake>
          <Icon icon="cake" color={COLORS.primary} />
          <Text type="h4" color={COLORS.navyBlue}>
            Aniversariantes de Janeiro
          </Text>
        </Cake>
        <Spacer size={16} />
        <ul>
          <Birthday>
            <Text type="sub1" color={COLORS.navyBlue}>
              07
            </Text>
            <Text type="sub2" color={COLORS.navyBlue}>
              José Victor Coutinho
            </Text>
          </Birthday>
          <Birthday>
            <Text type="sub1" color={COLORS.navyBlue}>
              11
            </Text>
            <Text type="sub2" color={COLORS.navyBlue}>
              Márcia Xavier, Charles Vinícius Santos, Manoel Rodrigues, Afonso
              Miguel Souza
            </Text>
          </Birthday>
          <Birthday>
            <Text type="sub1" color={COLORS.navyBlue}>
              14
            </Text>
            <Text type="sub2" color={COLORS.navyBlue}>
              Daniel Magalhães
            </Text>
          </Birthday>
        </ul>
      </Dialog>
    </>
  );
};

const Flex = styled.div`
  display: flex;
  column-gap: 12px;
  align-items: center;
`;

const FlexBirth = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;
`;

const Cake = styled.div`
  display: flex;
  column-gap: 24px;
  align-items: center;
`;

const Birthday = styled.li`
  display: flex;
  column-gap: 12px;
  margin-bottom: 12px;
`;

import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@reach/tabs";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  Back,
  Breadcrumb,
  Card,
  Icon,
  Spacer,
  Text,
} from "../../components/shared";
import COLORS from "../../template/colors";

export const Users = () => (
  <>
    <Spacer size={47} />
    <Flex>
      <Back />
      <div>
        <Text type="h2" color={COLORS.white}>
          Usuários
        </Text>
      </div>
    </Flex>
    <Spacer size={4} />
    <Breadcrumb
      margin="0 0 0 60px"
      items={[{ name: "Usuários", url: "/usuarios" }]}
    />
    <Spacer size={36} />
    <div className="row">
      <div className="col">
        <Card icon="users" title="Usuários">
          <FlexCard>
            <div>
              <Text type="sub2" color={COLORS.navyBlue}>
                Novos usuários esse mês
              </Text>
              <Spacer size={4} />
              <Text type="h4" color={COLORS.navyBlue}>
                5
              </Text>
            </div>
            <div>
              <Text type="sub2" color={COLORS.navyBlue}>
                Usuários removidos esse mês
              </Text>
              <Spacer size={4} />
              <Text type="h4" color={COLORS.navyBlue}>
                3
              </Text>
            </div>
          </FlexCard>
          <Spacer size={40} />
        </Card>
      </div>
      <div className="col">
        <Card icon="notice" title="Comunicação Interna">
          <FlexCard>
            <div>
              <Text type="sub2" color={COLORS.navyBlue}>
                Enviados neste mês
              </Text>
              <Spacer size={4} />
              <Text type="h4" color={COLORS.navyBlue}>
                23 vezes
              </Text>
            </div>
            <div>
              <Text type="sub2" color={COLORS.navyBlue}>
                Celebraram com você
              </Text>
              <Spacer size={4} />
              <Text type="h4" color={COLORS.navyBlue}>
                3 vezes
              </Text>
            </div>
          </FlexCard>

          <Spacer size={23} />
          <Link to="#">
            <Text type="button" color={COLORS.primary}>
              Ver atividades recentes
            </Text>
          </Link>
        </Card>
      </div>
    </div>
    <Spacer size={41} />
    <Tabs>
      <TabList>
        <Tab>Todas celebrações</Tab>
        <Tab>Minhas celebrações</Tab>
        <Tab>Celebrações comigo</Tab>
      </TabList>
      <Spacer size={40} />
      <TabPanels>
        <TabPanel>
          <Element>
            <Person>
              <img
                src="https://randomuser.me/api/portraits/thumb/men/55.jpg"
                alt="Carlos Santana"
              />
              <div>
                <Text type="sub1" color={COLORS.navyBlue}>
                  Carlos Santana
                </Text>
                <Text type="body2" color={COLORS.gray}>
                  Desenvolvedor Full Stack
                </Text>
              </div>
            </Person>
            <div>
              <Text type="body2" color={COLORS.navyBlue}>
                Lorem ipsum dolor sit amet consectetur. Ac iaculis diam
                suspendisse condimentum consectetur imperdiet odio. Nibh eget
                lorem leo quis ullamcorper. Odio vitae amet ornare in dignissim
                blandit lorem. Integer rhoncus facilisis proin nulla tellus
                egestas mauris pretium. Enim vel justo ac viverra. Purus et
                donec ornare nunc accumsan. Augue morbi nunc eget egestas
                elementum auctor. Amet eu eget quam hac fermentum maecenas
                pretium mattis eget. Sit convallis eros quis amet pellentesque.
                Elementum augue sed pulvinar et morbi aliquam suspendisse
                habitant semper. Urna tristique sit tellus eu. Velit ac odio
                tincidunt phasellus placerat eget nunc hac porta. Quam facilisi
                id mollis faucibus adipiscing quisque leo. Senectus eget nulla
                ultrices auctor porttitor faucibus.
              </Text>
              <Spacer size={8} />
              <Text type="body2" color={COLORS.gray}>
                18/01/2023 às 14:52
              </Text>
            </div>
            <Actions>
              <button onClick={() => {}}>
                <Icon icon="like" color={COLORS.gray} />
              </button>
              <button onClick={() => {}}>
                <div>
                  <Icon icon="comment" color={COLORS.gray} />
                </div>
              </button>
            </Actions>
          </Element>
          <Spacer size={16} />
          <Element>
            <Person>
              <img
                src="https://randomuser.me/api/portraits/thumb/men/55.jpg"
                alt="Carlos Santana"
              />
              <div>
                <Text type="sub1" color={COLORS.navyBlue}>
                  Carlos Santana
                </Text>
                <Text type="body2" color={COLORS.gray}>
                  Desenvolvedor Full Stack
                </Text>
              </div>
            </Person>
            <div>
              <Text type="body2" color={COLORS.navyBlue}>
                Lorem ipsum dolor sit amet consectetur. Ac iaculis diam
                suspendisse condimentum consectetur imperdiet odio. Nibh eget
                lorem leo quis ullamcorper. Odio vitae amet ornare in dignissim
                blandit lorem. Integer rhoncus facilisis proin nulla tellus
                egestas mauris pretium. Enim vel justo ac viverra. Purus et
                donec ornare nunc accumsan. Augue morbi nunc eget egestas
                elementum auctor. Amet eu eget quam hac fermentum maecenas
                pretium mattis eget. Sit convallis eros quis amet pellentesque.
                Elementum augue sed pulvinar et morbi aliquam suspendisse
                habitant semper. Urna tristique sit tellus eu. Velit ac odio
                tincidunt phasellus placerat eget nunc hac porta. Quam facilisi
                id mollis faucibus adipiscing quisque leo. Senectus eget nulla
                ultrices auctor porttitor faucibus.
              </Text>
              <Spacer size={8} />
              <Text type="body2" color={COLORS.gray}>
                18/01/2023 às 14:52
              </Text>
            </div>
            <Actions>
              <button onClick={() => {}}>
                <Icon icon="like" color={COLORS.gray} />
              </button>
              <button onClick={() => {}}>
                <div>
                  <Icon icon="comment" color={COLORS.gray} />
                </div>
              </button>
            </Actions>
          </Element>
          <Spacer size={16} />
        </TabPanel>
        <TabPanel>
          <Element>
            <div>
              <Person>
                <img
                  src="https://randomuser.me/api/portraits/thumb/men/55.jpg"
                  alt="Carlos Santana"
                />
                <div>
                  <Text type="sub1" color={COLORS.navyBlue}>
                    Carlos Santana
                  </Text>
                  <Text type="body2" color={COLORS.gray}>
                    Desenvolvedor Full Stack
                  </Text>
                </div>
              </Person>
              <Spacer size={27} />
              <Text type="caption" color={COLORS.black}>
                Enviado para:
              </Text>
              <Spacer size={11} />
              <Sent>
                <img
                  src="https://randomuser.me/api/portraits/thumb/men/55.jpg"
                  alt="Carlos Santana"
                />

                <Text type="sub1" color={COLORS.navyBlue}>
                  Carlos Santana
                </Text>
              </Sent>
            </div>
            <div>
              <Text type="body2" color={COLORS.navyBlue}>
                Lorem ipsum dolor sit amet consectetur. Ac iaculis diam
                suspendisse condimentum consectetur imperdiet odio. Nibh eget
                lorem leo quis ullamcorper. Odio vitae amet ornare in dignissim
                blandit lorem. Integer rhoncus facilisis proin nulla tellus
                egestas mauris pretium. Enim vel justo ac viverra. Purus et
                donec ornare nunc accumsan. Augue morbi nunc eget egestas
                elementum auctor. Amet eu eget quam hac fermentum maecenas
                pretium mattis eget. Sit convallis eros quis amet pellentesque.
                Elementum augue sed pulvinar et morbi aliquam suspendisse
                habitant semper. Urna tristique sit tellus eu. Velit ac odio
                tincidunt phasellus placerat eget nunc hac porta. Quam facilisi
                id mollis faucibus adipiscing quisque leo. Senectus eget nulla
                ultrices auctor porttitor faucibus.
              </Text>
              <Spacer size={8} />
              <Text type="body2" color={COLORS.gray}>
                18/01/2023 às 14:52
              </Text>
            </div>
            <Actions>
              <button onClick={() => {}}>
                <Icon icon="like" color={COLORS.gray} />
              </button>
              <button onClick={() => {}}>
                <div>
                  <Icon icon="comment" color={COLORS.gray} />
                </div>
              </button>
            </Actions>
          </Element>
          <Spacer size={16} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  </>
);

const Element = styled.div`
  background-color: ${COLORS.grayBg};
  padding: 25px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  column-gap: 56px;

  img {
    border-radius: 50%;
    width: 48px;
    height: 48px;
  }
`;

const Sent = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;

  img {
    width: 27px;
    height: 27px;
  }
`;

const Flex = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;
`;

const Person = styled.div`
  display: flex;
  column-gap: 16px;
  white-space: nowrap;
`;

const Actions = styled.div`
  display: flex;
  column-gap: 23px;
  align-items: flex-start;

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;

    div {
      display: inline-flex;
      column-gap: 12px;
    }
  }
`;

const FlexCard = styled.div`
  display: flex;
  justify-content: space-between;
`;

import { Link } from "react-router-dom";
import styled from "styled-components";
import { Card, Icon, Spacer, Text } from "../../components/shared";
import COLORS from "../../template/colors";

export const Profile = () => (
  <>
    <Spacer size={50} />
    <Info>
      <UserInfo>
        <UserPicture src="/assets/images/user.jpg" alt="User" />
        <div>
          <Text type="h2" color={COLORS.white}>
            Marina Lima
          </Text>
          <Text type="body2" color={COLORS.white}>
            Auxiliar Administrativo
          </Text>
        </div>
      </UserInfo>
      <EditButton to="/editar-perfil">
        <Text type="button" color={COLORS.white}>
          Editar Perfil
        </Text>
      </EditButton>
    </Info>
    <Spacer size={26} />
    <div className="row">
      <div className="col-8">
        <Text type="h4" color={COLORS.black}>
          Sobre mim
        </Text>
        <Spacer size={16} />
        <Text type="body1" color={COLORS.navyBlue}>
          Lorem ipsum dolor sit amet consectetur. Facilisi imperdiet elit
          gravida scelerisque purus neque at. Commodo mi feugiat augue magna
          pellentesque risus blandit mauris non. Elit risus faucibus amet eu.
          Nisl condimentum tristique sit sem. Commodo eu ultrices bibendum
          vulputate erat proin feugiat ac velit. Sit in consectetur elementum
          lacinia at arcu eget. Sociis felis volutpat risus nec nunc molestie
          dapibus.
        </Text>
      </div>
      <div className="col">
        <Spacer size={50} />
        <MoreInfo>
          <Icon icon="cake" color={COLORS.primary} />
          <Spacer size={8} direction="vertical" />
          <Text type="sub1" color={COLORS.black}>
            Aniversário:
          </Text>
          <Text type="sub2" color={COLORS.black}>
            14/06
          </Text>
        </MoreInfo>
        <Spacer size={8} />
        <MoreInfo>
          <Icon icon="email" color={COLORS.primary} />
          <Spacer size={8} direction="vertical" />
          <Text type="sub1" color={COLORS.black}>
            E-mail:
          </Text>
          <Text type="sub2" color={COLORS.black}>
            marina.lima@rethink.com
          </Text>
        </MoreInfo>
      </div>
    </div>
    <Spacer size={40} />
    <div className="row">
      <div className="col">
        <Text type="h4" color={COLORS.black}>
          Atividades
        </Text>
        <Spacer size={12} />
        <div className="row">
          <div className="col-4">
            <Card icon="chat" title="Feedback">
              <FlexCard>
                <div>
                  <Text type="sub2" color={COLORS.navyBlue}>
                    Enviou
                  </Text>
                  <Spacer size={4} />
                  <Text type="h4" color={COLORS.navyBlue}>
                    5
                  </Text>
                </div>
                <div>
                  <Text type="sub2" color={COLORS.navyBlue}>
                    Recebeu
                  </Text>
                  <Spacer size={4} />
                  <Text type="h4" color={COLORS.navyBlue}>
                    3
                  </Text>
                </div>
              </FlexCard>

              <Spacer size={23} />
              <Link to="#">
                <Text type="button" color={COLORS.primary}>
                  Ver feedbacks
                </Text>
              </Link>
            </Card>
          </div>
          <div className="col-4">
            <Card icon="glass" title="Celebrações">
              <FlexCard>
                <div>
                  <Text type="sub2" color={COLORS.navyBlue}>
                    Enviou
                  </Text>
                  <Spacer size={4} />
                  <Text type="h4" color={COLORS.navyBlue}>
                    5
                  </Text>
                </div>
                <div>
                  <Text type="sub2" color={COLORS.navyBlue}>
                    Recebeu
                  </Text>
                  <Spacer size={4} />
                  <Text type="h4" color={COLORS.navyBlue}>
                    3
                  </Text>
                </div>
              </FlexCard>

              <Spacer size={23} />
              <Link to="#">
                <Text type="button" color={COLORS.primary}>
                  Ver celebrações
                </Text>
              </Link>
            </Card>
          </div>
        </div>
      </div>
    </div>
  </>
);

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const UserInfo = styled.div`
  display: flex;
  column-gap: 25px;
  justify-content: space-between;
  align-items: center;
`;

const UserPicture = styled.img`
  width: 168px;
  height: 168px;
  border-radius: 50%;
  outline: 1px solid #e0e0e0;
  padding: 6px;
`;

const EditButton = styled(Link)`
  background-color: ${COLORS.primary};
  display: block;
  color: ${COLORS.white};
  padding: 14px 42px;
  border-radius: 8px;
`;

const MoreInfo = styled.div`
  display: flex;
  column-gap: 3px;
  align-items: center;
`;

const FlexCard = styled.div`
  display: flex;
  justify-content: space-between;
`;

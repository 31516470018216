import styled from "styled-components";
import COLORS from "../../../template/colors";
import { Icon, Spacer, Text } from "..";
import { IconTypes } from "../icon/Icon";
import { Input } from "../form";
import { useState } from "react";

interface Props {
  info: {
    person: {
      image: string;
      name: string;
      status: string;
    };
    text: {
      icon: IconTypes;
      iconBgColor?: string;
      title: string;
      status: string | React.ReactNode;
    };
  };
}

export const List = ({ info }: Props): JSX.Element => {
  const [isCommentsVisible, setCommentsVisible] = useState(false);

  return (
    <>
      <Activity>
        <Element>
          <Wrapper>
            <Person>
              <img src={info.person.image} alt={info.person.name} />
              <div>
                <Text type="sub1" color={COLORS.navyBlue}>
                  {info.person.name}
                </Text>
                <Text type="body2" color={COLORS.gray}>
                  {info.person.status}
                </Text>
              </div>
            </Person>
            <Message>
              <IconWrapper
                iconBgColor={info.text.iconBgColor || COLORS.primary}
              >
                <Icon icon={info.text.icon} color={COLORS.white} width={20} />
              </IconWrapper>
              <div>
                <Text type="sub1" color={COLORS.navyBlue}>
                  {info.text.title}
                </Text>
                <Text type="body2" color={COLORS.gray}>
                  {info.text.status}
                </Text>
              </div>
            </Message>
          </Wrapper>
          <Actions>
            <button onClick={() => {}}>
              <div>
                <Icon icon="like" color={COLORS.gray} />
                <Text type="button" color={COLORS.gray}>
                  6
                </Text>
              </div>
            </button>
            <button onClick={() => setCommentsVisible(!isCommentsVisible)}>
              <div>
                <Icon icon="comment" color={COLORS.gray} />
                <Text type="button" color={COLORS.gray}>
                  3
                </Text>
              </div>
            </button>
          </Actions>
        </Element>
        {isCommentsVisible && (
          <Comments>
            <Spacer size={16} />
            <Text type="h5" color={COLORS.black}>
              Comentários
            </Text>
            <Spacer size={8} />
            <Item>
              <Wrapper>
                <Person>
                  <img src={info.person.image} alt={info.person.name} />
                  <div>
                    <Text type="sub1" color={COLORS.navyBlue}>
                      {info.person.name}
                    </Text>
                    <Text type="body2" color={COLORS.gray}>
                      {info.person.status}
                    </Text>
                  </div>
                </Person>
                <Message>
                  <div>
                    <Text type="body2" color={COLORS.navyBlue}>
                      Mattis netus lorem feugiat in fermentum eu elementum
                      vitae. Scelerisque facilisi duis purus adipiscing
                      consectetur turpis justo.
                    </Text>
                    <Text type="body2" color={COLORS.gray}>
                      {info.text.status}
                    </Text>
                  </div>
                </Message>
                <Actions>
                  <button onClick={() => {}}>
                    <div>
                      <Icon icon="like" color={COLORS.gray} />
                      <Text type="button" color={COLORS.gray}>
                        6
                      </Text>
                    </div>
                  </button>
                  <button onClick={() => {}}>
                    <div>
                      <Icon icon="trash" color={COLORS.gray} width={16} />
                    </div>
                  </button>
                </Actions>
              </Wrapper>
            </Item>
            <Spacer size={8} />
            <Item>
              <Wrapper>
                <Person>
                  <img src={info.person.image} alt={info.person.name} />
                  <div>
                    <Text type="sub1" color={COLORS.navyBlue}>
                      {info.person.name}
                    </Text>
                    <Text type="body2" color={COLORS.gray}>
                      {info.person.status}
                    </Text>
                  </div>
                </Person>
                <Message>
                  <div>
                    <Text type="body2" color={COLORS.navyBlue}>
                      Mattis netus lorem feugiat in fermentum eu elementum
                      vitae. Scelerisque facilisi duis purus adipiscing
                      consectetur turpis justo.
                    </Text>
                    <Text type="body2" color={COLORS.gray}>
                      {info.text.status}
                    </Text>
                  </div>
                </Message>
                <Actions>
                  <button onClick={() => {}}>
                    <div>
                      <Icon icon="like" color={COLORS.gray} />
                      <Text type="button" color={COLORS.gray}>
                        6
                      </Text>
                    </div>
                  </button>
                  <button onClick={() => {}}>
                    <div>
                      <Icon icon="trash" color={COLORS.gray} width={16} />
                    </div>
                  </button>
                </Actions>
              </Wrapper>
            </Item>
            <Spacer size={12} />
            <Text type="button" color={COLORS.primary} align="center">
              Ver mais
            </Text>
            <Spacer size={16} />
            <Input type="text" placeholder="Digite seu comentário" />
            <Spacer size={8} />
            <WrapperAttachments>
              <Attachments>
                <button>
                  <Icon icon="emoji" color={COLORS.gray} />
                </button>
                <button>
                  <Icon icon="gif" color={COLORS.gray} />
                </button>
                <button>
                  <Icon icon="photo" color={COLORS.gray} />
                  Imagem
                </button>
              </Attachments>
              <SendButton>Enviar</SendButton>
            </WrapperAttachments>
          </Comments>
        )}
      </Activity>
      <Spacer size={16} />
    </>
  );
};

const Activity = styled.div`
  background-color: ${COLORS.grayBg};
  padding: 25px;
  border-radius: 8px;
`;

const Element = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  display: flex;
`;

const Person = styled.div`
  display: flex;
  column-gap: 16px;
  width: 300px;

  img {
    border-radius: 50%;
    width: 48px;
    height: 48px;
  }
`;

const Message = styled.div`
  display: flex;
  column-gap: 16px;
`;

const IconWrapper = styled.div<any>`
  width: 40px;
  height: 40px;
  background-color: ${({ iconBgColor }: any) => iconBgColor};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Actions = styled.div`
  display: flex;
  column-gap: 23px;

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;

    div {
      display: inline-flex;
      column-gap: 12px;
    }
  }
`;

const Comments = styled.div`
  // display: none;
`;

const Item = styled.div`
  background-color: ${COLORS.primary}1A;
  border-radius: 8px;
  padding: 16px;

  img {
    width: 28px;
    height: 28px;
  }
`;

const WrapperAttachments = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Attachments = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;

  button {
    border: 1px solid ${COLORS.gray};
    border-radius: 6px;
    padding: 10px;
    height: 40px;
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
`;

const SendButton = styled.button`
  background-color: ${COLORS.primary};
  color: ${COLORS.white};
  border-radius: 8px;
  padding: 14px 32px;
`;

import styled from "styled-components";
import COLORS from "../../../template/colors";
import { Icon, Spacer, Text } from "..";
import { IconTypes } from "../icon/Icon";

interface Props {
  icon: IconTypes;
  title: string;
  children: React.ReactNode;
}

export const Card = ({ icon, title, children }: Props): JSX.Element => {
  return (
    <Element>
      <Title>
        <Icon icon={icon} color={COLORS.primary} width={27} />
        <Text type="h4" color={COLORS.navyBlue}>
          {title}
        </Text>
      </Title>
      <Spacer size={24} />
      {children}
    </Element>
  );
};

const Element = styled.div`
  background-color: ${COLORS.grayBg};
  padding: 29px 32px;
  border-radius: 8px;
`;

const Title = styled.div`
  display: flex;
  column-gap: 24px;
  align-items: center;
`;

import styled from "styled-components";
import COLORS from "../../../template/colors";
import { Icon, IconTypes } from "../icon/Icon";

interface Props {
  type: "text" | "password";
  placeholder?: string;
  icon?: IconTypes;
  onChange?: Function;
}

export const Input = ({ type, placeholder = "", icon, onChange }: Props) => (
  <Wrapper>
    {icon && <Icon icon={icon} color="#bdbdbd" />}

    <StyledInput type={type} placeholder={placeholder} onChange={onChange} />
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  background-color: ${COLORS.white};
  padding: 15px;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  column-gap: 12px;
`;

const StyledInput = styled.input<any>`
  background: ${COLORS.white};
  border: none;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: #bdbdbd;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #bdbdbd;
  }

  ::-ms-input-placeholder {
    color: #bdbdbd;
  }
`;

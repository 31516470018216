import styled from "styled-components";
import COLORS from "../../../template/colors";
import { Icon, Text } from "..";
import { Link } from "react-router-dom";

interface Props {
  items: {
    name: string;
    url: string;
  }[];
  margin?: string | number;
}

export const Breadcrumb = ({ items, margin = 0 }: Props): JSX.Element => {
  return (
    <nav>
      <Items margin={margin}>
        <li>
          <Link to="/inicio">
            <Icon icon="home" color={COLORS.white} width={18} />
          </Link>
        </li>
        {items.map((item) => (
          <li>
            <svg
              width="7"
              height="11"
              viewBox="0 0 7 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M6.84375 5.78125C7 5.625 7 5.40625 6.84375 5.25L2.28125 0.625C2.125 0.46875 1.875 0.46875 1.75 0.625L1.125 1.25C0.96875 1.375 0.96875 1.625 1.125 1.78125L4.8125 5.5L1.125 9.25C0.96875 9.40625 0.96875 9.625 1.125 9.78125L1.75 10.4062C1.875 10.5625 2.125 10.5625 2.28125 10.4062L6.84375 5.78125Z"
                fill="white"
              />
            </svg>
            <Link to={item.url}>
              <Text type="sub2" color={COLORS.white}>
                {item.name}
              </Text>
            </Link>
          </li>
        ))}
      </Items>
    </nav>
  );
};

const Items = styled.ul<any>`
  display: flex;
  align-items: center;
  column-gap: 17px;
  margin: ${({ margin }: any) => margin};

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 17px;

    a {
      display: flex;
      align-items: center;
    }
  }
`;
